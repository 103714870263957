<template>
  <div
    ref="element"
    class="ps"
    @scroll="$emit('scroll')"
    @ps-scroll-y="$emit('ps-scroll-y')"
    @ps-scroll-x="$emit('ps-scroll-x')"
    @ps-scroll-up="$emit('ps-scroll-up')"
    @ps-scroll-down="$emit('ps-scroll-down')"
    @ps-scroll-left="$emit('ps-scroll-left')"
    @ps-scroll-right="$emit('ps-scroll-right')"
    @ps-y-reach-start="$emit('ps-y-reach-start')"
    @ps-y-reach-end="$emit('ps-y-reach-end')"
    @ps-x-reach-start="$emit('ps-x-reach-start')"
    @ps-x-reach-end="$emit('ps-x-reach-end')">
    <div class="h-full">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import PerfectScrollbar from 'perfect-scrollbar';
import type { PropType } from 'vue';

defineEmits([
  'scroll',
  'ps-scroll-y',
  'ps-scroll-x',
  'ps-scroll-up',
  'ps-scroll-down',
  'ps-scroll-left',
  'ps-scroll-right',
  'ps-y-reach-start',
  'ps-y-reach-end',
  'ps-x-reach-start',
  'ps-x-reach-end',
]);
const props = defineProps({
  handlers: {
    type: Array as PropType<string[]>,
    default: () => ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  },
  width: { type: Number, default: 5 },
  wheelSpeed: { type: Number, default: 1 },
  wheelPropagation: { type: Boolean, default: false },
  swipeEasing: { type: Boolean, default: true },
  minScrollbarLength: { type: Number, default: null },
  scrollingThreshold: { type: Number, default: 1000 },
  useBothWheelAxes: { type: Boolean, default: false },
  suppressScrollX: { type: Boolean, default: true },
  suppressScrollY: { type: Boolean, default: false },
  scrollXMarginOffset: { type: Number, default: 0 },
  scrollYMarginOffset: { type: Number, default: 0 },
  scrollbarOffset: { type: String, default: '0px' },
});

const element = ref();
const ps = ref();
const create = () => {
  if (!ps.value) {
    ps.value = new PerfectScrollbar(element.value, { ...props });
    element.value.addEventListener('touchstart', () => {}, {
      capture: true,
      passive: true,
    });
  }
};
const destroy = () => {
  if (ps.value) {
    ps.value.destroy();
    ps.value = null;
  }
};

onMounted(() => {
  nextTick(() => {
    create();
  });
});

onBeforeUnmount(() => {
  destroy();
});
</script>

<style>
@import 'perfect-scrollbar/css/perfect-scrollbar.css';
.ps {
  height: 100%;
  position: relative;
}
.ps__rail-x {
  z-index: 999;
  bottom: 0px;
  width: v-bind(`${props.width}px`);
}

.ps__rail-y {
  @apply rounded-full bg-transparent;
  width: v-bind(`${props.width}px`);
  z-index: 999 !important;
  top: 0px;
}

.ps__thumb-x {
  @apply bg-secondary-500;
  width: v-bind(`${props.width}px`);
  z-index: 9999999;
}

.ps__thumb-y {
  @apply bg-secondary-500;
  width: v-bind(`${props.width}px`);
  z-index: 9999999 !important;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: v-bind(`${props.width}px`);
  @apply bg-secondary-500;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  height: v-bind(`${props.width}px`);
  @apply bg-secondary-500;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  @apply bg-transparent;
}

.ps.ps--active-y {
  padding-left: v-bind(`${props.width + 3}px`);
  z-index: 999;
}

.ps:not(.ps--active-y) {
  padding-right: 0px;
  padding-left: 0px;
}

*[dir='ltr'] .ps:not(.ps--active-y) {
  margin-left: v-bind(props.scrollbarOffset);
  padding-left: 0px;
  padding-right: 0px;
}

*[dir='ltr'] .ps__rail-y {
  right: auto;
}

*[dir='ltr'] .ps__thumb-y {
  left: v-bind(props.scrollbarOffset);
  right: auto;
}

*[dir='ltr'] .ps__rail-x {
  right: v-bind(`${props.width + 3}px`);
}

*[dir='ltr'] .ps__thumb-x {
  right: v-bind(`${props.width + 3}px`);
}

*[dir='ltr'] .ps.ps--active-y:not(.ps--active-x) {
  padding-left: v-bind(`${props.width + 3}px`);
  padding-right: 0px;
  width: calc(100% + v-bind(`${props.width + 3}px`));
}

*[dir='ltr'] .ps.ps--active-x {
  padding-right: 0px;
  width: calc(100% + v-bind(`${props.width + 3}px`));
}

*[dir='ltr'] div > .ps.ps--active-y {
  margin-left: v-bind(`-${props.width + 3}px`);
  margin-right: 0px;
}

*[dir='ltr'] .ps:not(.ps--active-y) > div {
  margin-right: 0px;
  margin-left: 0px;
}

*[dir='rtl'] .ps:not(.ps--active-y) {
  margin-right: v-bind(props.scrollbarOffset);
  padding-right: 0px;
  padding-left: 0px;
}

*[dir='rtl'] .ps__rail-y {
  right: auto;
}

*[dir='rtl'] .ps__thumb-y {
  left: v-bind(props.scrollbarOffset);
  right: auto;
}

*[dir='rtl'] .ps__rail-x {
  left: v-bind(`${props.width + 3}px`);
}

*[dir='rtl'] .ps__thumb-x {
  left: v-bind(`${props.width + 3}px`);
}

*[dir='rtl'] .ps.ps--active-y:not(.ps--active-x) {
  padding-right: v-bind(`${props.width + 3}px`);
  padding-left: 0px;
  width: calc(100% + v-bind(`${props.width + 3}px`));
}

*[dir='rtl'] .ps.ps--active-x {
  padding-left: 0px;
  width: calc(100% + v-bind(`${props.width + 3}px`));
}

*[dir='rtl'] div > .ps.ps--active-y {
  margin-right: v-bind(`-${props.width + 3}px`);
  margin-left: 0px;
}

*[dir='rtl'] .ps:not(.ps--active-y) > div {
  margin-right: 0px;
  margin-left: 0px;
}
</style>
